.mp-view {

  .logo-container {
    padding-top: 20px;
    > .logo {
      background-image: url(../img/image-placeholder.png);
      width: 110px;
      height: 110px;
      border-radius: 50%;
      margin:auto;
      background-position: center;
      background-size: contain;
    }
  }

  .title{
      text-align: center;
      font-size: 35px;
      color:black;
      white-space: pre-wrap;
      width: 90%;
      margin:auto;
      margin-top: 20px;
  }

  .subtitle{
      text-align: center;
      font-size: 28px;
      color:black;
      white-space: pre-wrap;
      width: 90%;
      margin:auto;
      margin-top: 20px;

  }

  .mp-button {
    margin-top: 40px;
    text-align: center;
  }

  .link-hash {
      text-align: center;
  }

  .button-container {
    margin-top: 20px;
    text-align: center;
  }

  .container-powered{
    text-align: center;
    position: absolute;
    bottom: 30px;
    width: 100%;
    max-width: inherit;
    a{
      >.poweredSmile {
        width: 120px;
      }
    }
  }
}