// .container {
//   width: 600px;
//   display:flex;
//   flex-direction: column;
// }

.productPhoto{
    width:inherit;
    // border-width: 1px;
    // border-color: black;
    // border-style: solid;
    text-align: center;
    >img{
      // width: 100%;
      height: 220px;
    }
}

.info {
  width: 95%;
  margin: auto;
  // margin-left:10px;
  // margin-right: 5px;
  >.name {
    font-size: 30px;
    font-weight: bold;
    color:black;
    line-height: 30px;
    margin-bottom: 10px;
   
    // margin-top:5px;
  }
  >.description {
    font-weight: bold;
    color:grey;
    font-size: 16px;
    margin-left:2px;
    margin-bottom: 10px;

  }
  >.price {
    font-weight: bold;
    color:black;
    font-size: 20px;
    margin-left:2px;
  }
}

.extraType {
  
  width: 95%;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 10px;

  >.info-type {
    
    display: flex;
    align-items: baseline;

    >.name {
      font-weight: bold;
      font-size: 20px;
      color:black;
    }
    >.cant {
      font-weight:500;
      font-size: 12px;
      color:grey;
      margin-left:5px;
    }
  }
  
  >.isRequired {
    // margin: 0px 0px 0px 10px ;
    display: inline-block;
    text-align: center;
    border-radius: 8px;
    background-color: var(--primary-color-light);
    color:var(--primary-color);
    // font-weight: bold;
    padding:0px 3px 0px 3px;
  }
}

.extra {
  // cursor: pointer;
  margin-left: 10px;
  line-height: 30px;
  >.name {
    font-weight: 500;
    font-size: 18px;
    color:black;
  
  }
  >.addPrice {
    text-align: center;
    color:var(--primary-color);
    display: flex;
    >div{
      background-color: var(--primary-color-light);
      border-radius: 8px;
      padding:2px 4px 0px 4px;
      line-height: 23px;
      margin: auto;
    }
  }
  >.none {
    opacity:0;
  }
  >.btn {
    font-size: 22px;
  }

}

.add-product-note {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  margin-top:24px;
  padding-bottom: 80px;
  >div{
    font-weight: bold;
    font-size: 20px;
    color:black;
    margin-bottom: 10px;
  }
  >.text{
    outline: none;
    resize:none;
    border-radius: 5px;
    border-color:var(--primary-color);
  }
 
}

.container-footer-add-product{
  width:100% ;
  max-width: inherit;
  background-color: white;
  position: fixed;
  bottom: -10px;
  z-index: 1;
  font-size: 18px;

  padding:12px 5px 27px 5px;

  >.footer-add-product {

    font-size: 18px;
    >.cant {
      border-color: var(--primary-color);
      border-style: solid;
      border-width: 1px;
      border-radius: 5px;
      margin-right: 10px;
      text-align: center;
      padding:5px;
    }
  
    .button-add {
      cursor: pointer;
      // background-color:#37ad90;
      border-radius: 5px;
      padding-left: 10px;
      color:white;
      padding:5px;
      width: 100%;
    }
  
  }  
}

.quantity-edit{
  // float:right;
  display: flex;
  height: max-content;
  align-self: center;
  border-color: var(--primary-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  margin-right: 10px;
  text-align: center;

  >div{
    width: 30px;
  }
}