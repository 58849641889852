.check-order-view {
  >.header {
    width: 90%;
    margin: auto;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    >.logo-container {
      > .logo {
          background-image: url(../img/image-placeholder.png);
          width: 110px;
          height: 110px;
          min-width: 110px;
          border-radius: 50%;
          background-position: center;
          background-size: contain;
      }
    }
    >.store-info {
      // padding-left: 125px;
      color:black;
      justify-content: center;
      margin-left: 25px;
      >.name {
        font-size: 30px;
        font-weight: bold;

        &.skeletoned {
          width: 50%;

          .ant-skeleton-title {
            margin-top: 10px;
            margin-bottom: 4px;
          }
        }
      }
      >.phone-number {
        font-weight: 500;

        &.skeletoned {
          width: 50%;

          .ant-skeleton-title {
            margin-top: 10px;
            margin-bottom: 4px;
          }
        }
      }
      >.description {
        font-weight: bold;
        color:grey;

        &.skeletoned {
          width: 60%;

          .ant-skeleton-title {
            margin-top: 10px;
            margin-bottom: 4px;
          }
        }
      }
      >.location {
        font-weight: bold;
        &.skeletoned {
          width: 50%;

          .ant-skeleton-title {
            margin-top: 4px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
  
  .container-order-products{
    margin:auto;
    background-color:white;
    padding-bottom: 15px;
    margin-top:10px;
    border-radius: 30px;
    >.title{
      color:black;
      font-size: 23px;
      font-weight: 500;
      padding-top:15px;
      margin: 0px 0px 10px 15px;
    }
    >.status{
      color:black;
      font-size: 23px;
      font-weight: 500;
      margin-left: 5px;
      text-align: center;
    }
    >.total{
      padding-top:10px;
      color:black;
      font-size: 23px;
      font-weight: 500;
      margin-left: 5px;
      text-align: center;
    }
  }
  
  .container-customer{

    border-radius: 30px;
    background-color:white;
    // border-style: solid;
    // width: 95%;
    margin: auto;
    margin-top: 10px;
    padding-bottom: 30px;
    font-weight: 500;
    overflow: auto;

    >.title {
      color:black;
      font-size: 23px;
      font-weight: 500;
      padding-top:15px;
      margin: 0px 0px 10px 15px;
    }
  
    >.customer{
      width: 80%;
      margin: auto;

      >div{
        color:black;
        font-size: 18px;
      }
      
    }

  }
  
  .quantity-checkorder{
    // float:right;
    display: flex;
    height: max-content;
    align-self: center;
    border-color: var(--primary-color);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    margin-right: 10px;
    text-align: center;
    place-content: center;
    // width: 70px;
    padding-left:5px;
    padding-right: 5px;
    font-size: 16px;
  
    >div{
      width: 20px;
    }
  }
  
  .container-grey{
    padding-top:10px;
    background-color:#f1f2f3d2 ;
  }
  .footer{
    height: 40px;
  }
  }

