.order-title{
  display:flex;
  position: sticky;
  top: 0;
  // height: 38px;
  z-index: 1;
  background-color: white;
  font-size: 20px;
  font-weight: 500;
  margin-left: 5px;
  color:black;
  line-height: 35px;
  margin-bottom: 10px;
  padding-left: 5px;
}

.vaciar {
  width:100% ;
  max-width: inherit;
  background-color: white;
  position: fixed;
  bottom: 72px;
  z-index: 1;
  justify-content: center;
  color:grey;
  font-weight: 500;
  font-size: 18px;
}

.orderProduct{

  display: flex;
  width: 90%;
  margin:auto;
  margin-bottom: 20px;
  border-radius: 10px;
  -webkit-box-shadow: 10px 6px 18px -9px rgba(0,0,0,0.54);
  -moz-box-shadow: 10px 6px 18px -9px rgba(0,0,0,0.54);
  box-shadow: 10px 6px 18px -9px rgba(0,0,0,0.54);
  font-weight: 500;
  font-size: 15px;
  
  list-style: none;
   
  > img {
    float:left;
    margin:5px;
    min-width: 100px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px; 
  }

  >.info-order{

    width: 100%;
    margin: auto;
    margin-left: 5px;
    >.name{
        color:black;
        display: -webkit-box;
        max-width: 100%;
        height: auto;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
  }
  
}

.quantity{
  // float:right;
  display: flex;
  height: max-content;
  align-self: center;
  border-color: var(--primary-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  margin-right: 10px;
  text-align: center;

  >div{
    width: 20px;
  }
}

.ant-popover {
  >.ant-popover-content{
    >.ant-popover-inner{
      >.ant-popover-inner-content{
        >.ant-popover-buttons{
          >.ant-btn-primary {
            background-color:  #faad14;
          }
        }
      }
    }
  }
}