
.categories {
    display:flex;
    position: sticky;
    top: 0;
    margin: 15px 5px 0px 5px;
    z-index: 1;
    background-color: white;
    overflow: auto;
    padding: 10px 0px 10px 0px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 6px 12px -14px rgba(0,0,0,0.59);
    -moz-box-shadow: 0px 6px 12px -14px rgba(0,0,0,0.59);
    box-shadow: 0px 6px 12px -14px rgba(0,0,0,1);
    
    >div{
      >.category {
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        border-color: grey;
        border-radius: 8px;
        padding: 5px;
        margin: 0px 5px 0px 5px;
        color:grey;
      }
      >.colored{
        background-color: grey;
        color:white;
      }
    }
  }



  .allProducts {
    width: 100%;
    padding:0px;
    padding-bottom: 80vh;
    >div {
      display:flex;
      flex-direction: column;
      >.category{
        color:black;
        margin:15px 0px 5px 7.5px;
        font-weight: 500;
      }
    }
  }
  .product {
    width: 100%;
    // height: auto;
    display: flex;
    margin-left: 2.5px;
  
    >div img {
      // float:left;
      margin:5px;
      width: 110px;
      height: 110px;
      object-fit: cover;
      border-radius: 10px;
      
    }
    >.info {
      width: 100%;
      margin: 3px 20px 0px 15px;
      position: relative;
      >.name {
        font-weight: bold;
        font-size: 21px;
        line-height: 23px;
        margin-bottom: 5px;

        display: -webkit-box;
        max-width: 100%;
        height: auto;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        
      }
      >.description{
        font-weight: 500;
        line-height: 18px;
        font-size: 16px;
        
        display: -webkit-box;
        max-width: 100%;
        height: auto;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        
      }
      >.footer{
        width: 100%;
        position: absolute;
        bottom: 0px;

        margin-top: 5px;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }