.ant-btn-primary,
.ant-btn-primary.ignore-disabled-style[disabled],
.ant-btn-primary:active {
  background: var(--primary-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);

  &:hover {
    background: var(--primary-color-dark);
    background-color: var(--primary-color-dark);
    border-color: var(--primary-color-dark);
  
  }
  &.radius {
    border-radius: 15px;
  }
}