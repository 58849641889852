.dataContainer {
  width: 90%;
  margin: auto;
  margin-top: 20px;
  font-size: 18px;
  color:black;
  display:flex;
  flex-direction: column;
  line-height: 22px;

  >div{
    margin: 10px 0px 0px 8px;

    //icon size
    >div{
      margin-top:3px;
      >div{
        >i{
        font-size: 22px;
        color:var(--primary-color);
        }
      }
    }
  }

}

.inputTextArea {
  width: 100%;
  outline: none;
  padding-left:5px;
  resize:none;
  border-radius: 5px;
  border-color:var(--primary-color);
}

.inputText{
  width: 100%;
  padding-left:5px;
  // margin-bottom: 10px;
  outline: none;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color:var(--primary-color);
}

.inputTime {

  outline: none;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color:var(--primary-color);
  margin:auto;
  background-color: white;
  width: 30%;
  text-align: center;
  // line-height: 22px;
  height: 24px;
}

.container-confirmation-observation {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  margin-top:20px;
  padding-bottom: 80px;
  >.text{
    outline: none;
    padding-left:5px;
    resize:none;
    border-radius: 5px;
    border-color:var(--primary-color);
  }
}

.checkout-title {

  display:flex;
  position: sticky;
  top: 0;
  // height: 38px;
  z-index: 1;
  background-color: white;
  font-size: 20px;
  font-weight: 500;
  margin-left: 5px;
  color:black;
  line-height: 35px;
  margin-bottom: 10px;
  padding-left: 5px;
}

form {
  max-width: inherit;
}

.submitBtn{
  opacity: 0;
  z-index: -1;
  position: absolute;
  bottom: 5px;
}