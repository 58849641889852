@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

// @font-face {
//   font-family: myFirstFont;
//   src: url('../font/HELVETICA.TTF');
// }
// *{
//   font-family: myFirstFont;
// }
*{
  font-family: 'Roboto', sans-serif;
}

.app-container{
  // default palette 

  --primary-color:#37ad90;
  --primary-color-light:#bde4da;
  --primary-color-dark:#1d7963;

}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ant-divider-horizontal{
  margin:7px !important;
  min-width: auto !important;
  width: auto !important;
}

.checkout-divider {

  min-width: 105% !important;
  width: 105% !important;

}

hr.solid {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin:2px 0px 2px 0px;
  cursor: default;

}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.negrita {
  font-weight:500 ;
  color:black;
  font-size:18px;
}


.App-link {
  color: #61dafb;
}

.container {
  min-height: 100vh;
  margin: auto;
  max-width: 600px;
  -webkit-box-shadow: -5px 0 5px -5px #333, 5px 0 5px -5px #333;
  -moz-box-shadow: -5px 0 5px -5px #333, 5px 0 5px -5px #333;
  box-shadow: -5px 0 5px -5px #333, 5px 0 5px -5px #333;
}


.app-body {
  // grid-area: app-body;
  font-size: calc(10px + 2vmin);
  margin-bottom: 60px;
  
}

.boton {
  background-color: var(--primary-color);
  color:white;
  border:none;
  border-radius: 10px;
  outline: none;
  &:hover{
    background-color: var(--primary-color-dark);
  }

}

.colored {

  color:var(--primary-color);

}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pointable{
  cursor: pointer;
}
.unpointable{
  cursor: default;
}

.closeContainer {
  width:100% ;
  max-width: inherit;
  position: fixed;
  z-index: 999;
  font-size: 16px;

  >.close{
    float: right;
    padding: 5px 15px;
    >.cross {
      background-color: rgba(0, 0, 0, 0.08);
      border-radius: 50%;
      padding: 5px;
    }  
  }
}

.whiteBar{
  display:flex;
  position: sticky;
  top: 0;
  height: 38px;
  z-index: 1;
  background-color: white;

}


  /* mixin for multiline */
  @mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
    overflow: hidden;
    position: relative;
    line-height: $lineHeight !important;
    max-height: $lineHeight * $lineCount; 
    margin-right: -1em;
    padding-right: 1em;
    &:before {
      content: '...';
      position: absolute;
      text-align: justify;
      right: 0;
      bottom: 0;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      background: $bgColor;
    }
  }
  
  .block-with-text {
    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white);  
  }

  .block-with-text-2lines {
    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white);  
  }

  .block-with-text-3lines {
    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3, $bgColor: white);  
  }


  @media (max-width: 900px) {
    .container{
      box-shadow: none;
      -webkit-box-shadow:none;
      -moz-box-shadow: none;

    }
  }


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
