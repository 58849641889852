.header {
  
    display: flex;
    flex-direction: column;
  
    >.cover {
      width: 100%;
      height: 150px;
      background-position: center;
      background-size: cover;
      background-color: #e1e2e3;
    }
  
    >.info {
      position:relative;
      // top:-105px;
      margin:0px 0px 0px 15px;
      padding-left: 125px;

      >.name {
        top: 0;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: -5px;

        &.skeletoned {
          width: 50%;

          .ant-skeleton-title {
            margin-top: 10px;
            margin-bottom: 4px;
          }
        }
      }
      >.description {
        font-weight: bold;
        color:grey;

        &.skeletoned {
          width: 60%;

          .ant-skeleton-title {
            margin-top: 10px;
            margin-bottom: 4px;
          }
        }
      }
      >.location {
        display: flex;
        font-weight: bold;
        white-space: pre-wrap;
        &.skeletoned {
          width: 50%;

          .ant-skeleton-title {
            margin-top: 4px;
            margin-bottom: 2px;
          }
        }
        >.location-link{
          margin-left: 10px;
        }
      }
    }
  
    >.hours {
      width: 90%;
      align-self: center;
      border-radius: 5px;
      background-color: var(--primary-color-light);
      color:var(--primary-color);
      margin-top:15px;
      >div{
        white-space: pre-wrap;
        text-align: center;
        font-weight: bold;
        margin:8px 0px 8px 0px;


      }
    }
  
    > .logo-container {
      position: relative;

      > .logo {
        background-image: url(../img/image-placeholder.png);
        width: 110px;
        height: 110px;
        border-radius: 50%;
        background-position: center;
        background-size: contain;

        &.absolute{
          position: absolute;
          top:-55px;
          left:20px;
        }
      }
    }
  
  }