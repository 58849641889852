
.container-cartFooter {
    width:100% ;
    max-width: inherit;
    background-color: white;
    position: fixed;
    bottom: 0;
    z-index: 1;
    padding: 10px;
    padding-bottom:15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    >.vaciar {
      color:grey;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 10px;
    }
    >.cartFooter {
      border-radius: 10px;
      background-color: var(--primary-color);
      color:white;
      padding: 10px;
      text-align: center;;
      font-weight: 500;
      font-size: 18px;
      width: 90%;
      // display: grid;
      // grid-template-columns: 1fr 6fr 1fr;
  
      >.count{
        >div{
          width: fit-content;
          padding: 0px 10px 0px 10px;
          background: var(--primary-color-dark);
          border-radius: 10px;
        }
      }
    }
    
  }